import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IUserManagementStore } from './user-management.model';
import { UserManagementStore } from './user-management.store';

@Injectable({ providedIn: 'root' })
export class UserManagementQuery extends Query<IUserManagementStore> {
  public constructor(protected store: UserManagementStore) {
    super(store);
  }
}
