<div class="row px-4 py-4 mx-0 upload-file-modal">
  <div class="col-12">
    <div class="row mb-4">
      <div class="col-12 px-0">
        <span class="text-uppercase size-24">{{ parameters.header }}</span>
      </div>
    </div>

    <hr />

    <div class="row mt-4">
      <div class="col-sm-12 col-md-6" *ngIf="parameters.caption">
        <label>
          <span>{{ parameters.caption }}</span>
        </label>
        <input
          class="col-12 px-0 pb-2 caption"
          [formControl]="title"
          [placeholder]="parameters.captionPlaceholder || ''"
          maxlength="20"
        />
        <div
          class="mt-2"
          *ngIf="
            parameters.caption &&
            title?.invalid &&
            !title?.hasError('maxlength')
          "
        >
          <label class="text-danger"> * Field is required </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-6" *ngIf="parameters?.types?.length">
        <label>Document Type </label>
        <select
          class="col-12 px-0 form-control select-classic"
          [(ngModel)]="selectedMediaType"
        >
          <option *ngFor="let k of parameters.types" [ngValue]="k">
            {{ k.description }}
          </option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="parameters.caption && title?.hasError('maxlength')">
      <label class="col text-danger"> * Only 20 characters allowed </label>
    </div>

    <div class="row my-3">
      <div class="col-12 file-list">
        <button
          class="btn-choose p-1 mr-3 choose-btn"
          (click)="fileInput.click()"
        >
          Choose File
          <input
            multiple
            #fileInput
            type="file"
            (change)="onFileSelected($event)"
            style="display: none"
            [accept]="parameters.accept"
          />
        </button>
        <label
          class="file-names mb-0"
          [ngClass]="{ 'text-danger': !fileList?.length }"
        >
          {{ fileNames }}
        </label>
      </div>

      <div class="col-12 px-0 selection-error">
        {{ selectionErrorMessage }}
      </div>

      <div class="col-12 mt-4">
        <div
          class="drop-files-square"
          [class]="dragZoneClass"
          (dragover)="onDragOver($event)"
          (drop)="onDrop($event)"
          (dragleave)="onDragLeave($event)"
        >
          <span class="size-24 drop-files-text">Drop file here</span>
        </div>
      </div>
    </div>
    <div class="validations" *ngIf="!uploading || !readyToClose">
      <div class="size-message" *ngIf="parameters.maxFileSize">
        Max file size: {{ parameters.maxFileSize! / 1000000 }}MB
      </div>
      <div class="supported-formats" *ngIf="parameters.allowedFormats?.length">
        Formats: {{ this.parameters.allowedFormats!.join(", ").toUpperCase() }}
      </div>
    </div>

    <div class="row" *ngIf="uploading || readyToClose">
      <div class="col-12 progress-bar-container">
        <div
          class="progress-bar progress-bar-info progress-bar-striped"
          role="progressbar"
          attr.aria-valuenow="{{ progress }}"
          aria-valuemin="0"
          aria-valuemax="100"
          [ngStyle]="{ width: progress + '%', height: '1em' }"
        ></div>
        <div class="progress-text">{{ progress }}%</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 error-message">
        {{ uploadErrorMessage }}
      </div>
    </div>

    <div class="row justify-content-end mt-5">
      <div class="col-12 text-right">
        <button
          class="col-lg-3 col-md-3 col-sm-5 col-xs-12 py-2 mx-2 btn btn-lg dark-gray text-uppercase my-auto"
          (click)="onCancel()"
        >
          <span class="size-16"> Cancel </span>
        </button>
        <button
          [disabled]="uploading || readyToClose"
          (click)="onUpload()"
          class="upload col-lg-4 col-md-3 col-sm-5 col-xs-12 py-2 btn btn-lg primary text-uppercase"
        >
          <span class="size-16">{{
            uploading || readyToClose ? "Saving..." : "Save"
          }}</span>
          <i
            *ngIf="uploading || readyToClose"
            class="pi pi-spin pi-spinner loading"
          ></i>
        </button>
      </div>
    </div>
  </div>
</div>
