import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IDocumentTypeState } from 'sustainment-models';
import { DocumentTypeStore } from './documenttype.store';

@Injectable({ providedIn: 'root' })
export class DocumentTypeQuery extends Query<IDocumentTypeState> {
  public constructor(protected store: DocumentTypeStore) {
    super(store);
  }
}
