/* eslint-disable @typescript-eslint/naming-convention */
import { ActivatedRoute } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import OktaSignIn, {
  CustomButton,
  RegistrationData,
} from '@okta/okta-signin-widget';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailValidationService } from './email-validation.service';
import { OktaService, UserApplicationApi } from 'sustainment-component';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class PortalOktaService extends OktaService {
  public widget: OktaSignIn;
  public validatingEmail$: Observable<boolean>;

  private _newUserEmail: string;
  private _validatingEmail = new Subject<boolean>();
  private _userName: string;

  private _signInWithGoogle: CustomButton = {
    title: 'Sign in with Google',
    className: 'social-auth-button social-auth-google-button link-button',
    click: (): void => {
      sessionStorage.setItem('authenticationSource', 'Google');

      this.widget.authClient.signInWithRedirect({
        originalUri: this.getRedirectUrl(),
        state: 'a',
        nonce: 'b',
        idp: environment.okta.googleId,
      });
    },
  };

  public constructor(
    private emailValidation: EmailValidationService,
    private confirmationService: ConfirmationService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private userApplicationApi: UserApplicationApi
  ) {
    super();
    this.initClient();
  }

  protected initClient(): void {
    this.validatingEmail$ = this._validatingEmail.asObservable();

    const authClient = new OktaAuth({
      issuer: environment.okta.baseUrl + '/oauth2/default',
      pkce: true,
      scopes: ['openid', 'email', 'profile', 'offline_access'],
      clientId: environment.okta.clientId,
      redirectUri: window.location.origin + '/login/callback',
      //https://github.com/okta/okta-auth-js/blob/master/docs/autoRenew-notice.md
      tokenManager: {
        autoRenew: true,
      },
      services: {
        autoRenew: false,
        autoRemove: true,
      },
    });

    this.widget = new OktaSignIn({
      authClient,
      baseUrl: environment.okta.baseUrl,
      redirectUri: window.location.origin + '/login/callback',
      clientId: environment.okta.clientId,
      logo: 'https://assets-global.website-files.com/651b17ebe9db010c53be05ab/6544387fe5ec89904563083e_sustainment-logo2%201.svg',
      logoText: 'Sustainment Portal',
      authParams: {
        issuer: environment.okta.baseUrl + '/oauth2/default',
        services: { autoRenew: true },
        pkce: true,
        scopes: ['openid', 'email', 'profile', 'offline_access'],
        display: 'page',
      },
      features: {
        registration: true,
        router: true,
      },
      useClassicEngine: true,
      customButtons: [this._signInWithGoogle],
      i18n: {
        en: {
          'primaryauth.title': 'Sign in',
          'primaryauth.submit': 'Login',
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.username.tooltip': 'Email',
          'primaryauth.password.placeholder': 'Password',
          'primaryauth.password.tooltip': 'Password',
          'password.forgot.email.or.username.placeholder': 'Email',
          'password.forgot.email.or.username.tooltip': 'Email',
          'registration.signup.label': "Don't have an account?",
          'registration.complete.confirm.text':
            "To finish signing in, check your email.\n If you don't see it in your inbox, check your spam folder",
          help: ' ',
        },
      },
      registration: {
        parseSchema: (schema, onSuccess): void => {
          schema.profileSchema.fieldOrder = [
            'firstName',
            'lastName',
            'email',
            'password',
          ];
          onSuccess(schema);
        },
        preSubmit: (schema, onSuccess): void => {
          const refCode = this.route.snapshot.queryParamMap.get('ref');
          if (refCode) {
            schema.referralCode = refCode;
          }

          this._validatingEmail.next(true);
          this.emailValidation
            .validate(schema.email?.toString() || '')
            .subscribe((validation) => {
              if (!validation.suspect) {
                this.updateNewUser(schema);
                onSuccess(schema);
              } else {
                this.ngZone.run(() =>
                  this.confirmationService.confirm({
                    key: 'emailValidationConfirm',
                    header: 'Email confirmation',
                    message: validation.suggestion
                      ? `Did you mean <b>${validation.suggestion}</b>?`
                      : `Is the email <b>${schema.email}</b> correct?`,
                    acceptIcon: 'null',
                    accept: () => {
                      if (validation.suggestion) {
                        schema.email = `${validation.suggestion}`;
                      }
                      this.updateNewUser(schema);
                      onSuccess(schema);
                    },
                    rejectIcon: 'null',
                    reject: (type: ConfirmEventType) => {
                      if (
                        type === ConfirmEventType.REJECT &&
                        validation.suggestion
                      ) {
                        this.updateNewUser(schema);
                        onSuccess(schema);
                        return;
                      }
                      this._validatingEmail.next(false);
                    },
                  })
                );
              }
            });
        },
      },
    });
  }

  private getRedirectUrl(): string {
    let url = window.location.origin + '/login';

    if (window.location.search) url += window.location.search;

    return url;
  }

  private updateNewUser(schema: RegistrationData): void {
    this._userName = `${schema.firstName} ${schema.lastName}`;
    this._newUserEmail = schema.email?.toString() || '';
  }
}
