<div class="login-container">
  <div class="widget-container">
    <div class="grid">
      <div class="col-12 sm:col-5">
        <div class="login-form-wrapper">
          <div id="okta-signin-container"></div>
        </div>
      </div>
      <div class="col-12 sm:col-7 col-visual">
        <div class="login-visual-wrapper">
          <div class="login-overlay"></div>
          <div class="text-wrapper">
            <div class="title">
              The Leading Supplier Relationship Management Platform for
              Manufacturers
            </div>
            <div class="section">
              <div class="subtitle">For Buyers</div>
              <ul class="list">
                <li>
                  <span class="pi pi-check-circle"></span>
                  Organize your Supplier Network, get Supplier Analytics and
                  achieve your strategic goals
                </li>
                <li>
                  <span class="pi pi-check-circle"></span>Run projects with your
                  Suppliers from Quote to Delivery
                </li>
              </ul>
              <div class="headline">
                Free for up to 5 users and 3 active projects
              </div>
            </div>
            <div class="section">
              <div class="subtitle">For Suppliers</div>
              <ul class="list">
                <li>
                  <span class="pi pi-check-circle"></span>Easy project
                  collaboration with your Customers
                </li>
                <li>
                  <span class="pi pi-check-circle"></span>
                  Network with Buyers to forge new relationships and grow your
                  business
                </li>
              </ul>
              <div class="headline">Always Free</div>
            </div>
            <div class="certified">
              <div class="text">
                Sustainment is incorporated as a Public Benefit Corporation to
                demonstrate and deepen our commitment to US manufacturing.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="load" *ngIf="loading">
    <img
      title="loading"
      class="loading-logo"
      src="/assets/sustainment-icons/sustain-icon-white.png"
    />
  </div>
</div>

<p-confirmDialog
  [key]="'emailValidationConfirm'"
  [closable]="true"
  [baseZIndex]="10000"
  styleClass="dialog-sm"
></p-confirmDialog>
