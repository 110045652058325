import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
  VendorFormatService,
  ClosableModalService,
  BookmarkAPI,
  mapVendorProfileProcessSubprocess,
} from 'sustainment-component';
import { SupplierListModel } from 'sustainment-models';
import { CapabilityActions } from '../capability/capability.action';
import { IBookmark, IFavorite } from 'sustainment-models';
import { BookmarkStore } from './bookmark.store';
import { CapabilityQuery } from '../capability/capability.query';

@Injectable({ providedIn: 'root' })
export class BookmarkActions {
  public constructor(
    private _api: BookmarkAPI,
    private _store: BookmarkStore,
    private _closeableModals: ClosableModalService,
    private _capabilityAction: CapabilityActions,
    private _vendorFormatService: VendorFormatService,
    private _capabilityQuery: CapabilityQuery
  ) {}

  public getBookmarks(): void {
    this._store.setLoading(true);
    this._store.update({ bookmarks: [] });
    this._capabilityQuery.select().subscribe((capabilities) => {
      this._api
        .getBookmarks()
        .pipe(this._addLogos)
        .subscribe((results: IBookmark[]) => {
          this._store.update({
            bookmarks: results || [],
          });

          results.forEach((bookmark) => {
            bookmark.favorites?.forEach(({ ...favorite }) => {
              if (favorite.vendor) {
                favorite.vendor = { ...favorite.vendor };

                favorite.vendor =
                  this._capabilityAction.mapVendorProfileIndustries(
                    favorite.vendor
                  );

                if (favorite.vendor?.processes && capabilities?.processList)
                  favorite.vendor.processes = mapVendorProfileProcessSubprocess(
                    favorite.vendor,
                    capabilities.processList
                  );
              }
            });
          });

          this._store.setLoading(false);
        });
    });
  }

  public setBookmarks(results: IBookmark[]): void {
    this._capabilityQuery.select().subscribe((capabilities) => {
      this._store.update({
        bookmarks: results || [],
      });

      results.forEach((bookmark) => {
        bookmark.favorites?.forEach(({ ...favorite }) => {
          if (favorite.vendor) {
            favorite.vendor = { ...favorite.vendor };

            favorite.vendor = this._capabilityAction.mapVendorProfileIndustries(
              favorite.vendor
            );

            if (favorite.vendor?.processes && capabilities?.processList)
              favorite.vendor.processes = mapVendorProfileProcessSubprocess(
                favorite.vendor,
                capabilities.processList
              );
          }
        });
      });
    });
  }

  public addVendorToBookmark(
    bookmark: IBookmark,
    vendor: SupplierListModel
  ): Observable<any> {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    return this._api.addVendorToBookmark(bookmark, vendor.sustainmentId).pipe(
      tap(() => {
        this._store.setLoading(false);
        this.getBookmarks();
      })
    );
  }

  /* eslint-disable */
  public deleteVendorToBookmark(favorite: IFavorite): Observable<any> {
    return this._api
      .deleteVendorToBookmark(favorite.favoriteId?.toString()!)
      .pipe(
        tap(() => {
          this._store.setLoading(false);
          this.getBookmarks();
        })
      );
  }
  /* eslint-enable */

  public createBookmark(bookmark: IBookmark): void {
    this._api
      .createBookmark(bookmark)
      .pipe(
        tap((newData: any) => {
          const oldValue = this._store.getValue();
          const bookmarks = oldValue.bookmarks
            ? oldValue.bookmarks.slice(0, oldValue.bookmarks.length)
            : [];
          bookmarks.push(newData);
          this._store.update({ bookmarks });
          this._store.setLoading(false);
          this._closeableModals.close();
        })
      )
      .subscribe(() => this._closeableModals.reset());
  }

  public updateBookmark(bookmark: IBookmark): void {
    this._api
      .updateBookmark(bookmark)
      .pipe(
        tap((newData: any) => {
          const oldValue = this._store.getValue();
          const bookmarks = oldValue.bookmarks.map((b) => {
            if (b.collectionId === bookmark.collectionId) {
              return {
                ...b,
                name: newData.name,
                description: newData.description,
              };
            }
            return b;
          });
          this._store.update({ bookmarks });
          this._store.setLoading(false);
          this._closeableModals.close();
        })
      )
      .subscribe(() => this._closeableModals.reset());
  }

  public deleteBookmark(bookmark: IBookmark): void {
    this._api
      .deleteBookmark(bookmark)
      .pipe(
        tap(() => {
          const oldValue = this._store.getValue();
          const bookmarks = oldValue.bookmarks.filter(
            (b) => b.collectionId !== bookmark.collectionId
          );
          this._store.update({ bookmarks });

          this._closeableModals.close();
        })
      )
      .subscribe(() => {
        this._store.update((currentValue) => ({
          bookmarks: currentValue.bookmarks.filter(
            (b) => b.collectionId !== bookmark.collectionId
          ),
        }));
        this._closeableModals.reset();
      });
  }

  public updateNoteValue(
    companySustainmentId: string,
    notesCount: number
  ): void {
    // Update Store new Book mark
    this._store.setLoading(true);
    const oldValue = this._store.getValue();

    if (!oldValue.bookmarks) {
      return;
    }

    const results = oldValue.bookmarks.map((b: IBookmark) => {
      if (
        b.favorites?.filter(
          (v) => v.companySustainmentId === companySustainmentId
        ).length
      ) {
        return {
          ...b,
          favorites: b.favorites.map((f) => {
            if (f.companySustainmentId === companySustainmentId) {
              return {
                ...f,
                vendor: {
                  ...f.vendor,
                  userNotes: notesCount,
                },
              };
            }
            return f;
          }),
        };
      }
      return b;
    });
    this._store.update({
      bookmarks: results as IBookmark[],
    });
    this._store.setLoading(false);
  }

  public setConnectionRequested(sustainmentId: string): void {
    const values = this._store.getValue().bookmarks;
    if (!values) return;

    const bookmarks = values.map((b: IBookmark) => {
      if (
        b.favorites?.filter((v) => v.vendor?.sustainmentId === sustainmentId)
          .length
      ) {
        return {
          ...b,
          favorites: b.favorites.map((f) => {
            if (f.vendor?.sustainmentId === sustainmentId) {
              return {
                ...f,
                vendor: {
                  ...f.vendor,
                  connectionRequested: true,
                },
              };
            }
            return f;
          }),
        };
      }
      return b;
    });

    this._store.update({ bookmarks });
  }

  public setInvited(companySustainmentId: string): void {
    const values = this._store.getValue().bookmarks;
    if (!values) return;

    const bookmarks = values.map((b) => {
      if (
        b.favorites?.filter(
          (v) => v.companySustainmentId === companySustainmentId
        ).length
      ) {
        return {
          ...b,
          favorites: b.favorites.map((f) => {
            if (f.vendor && f.companySustainmentId === companySustainmentId) {
              return {
                ...f,
                vendor: {
                  ...f.vendor,
                  inviteRequested: true,
                },
              };
            }
            return f;
          }),
        };
      }
      return b;
    });

    this._store.update({ bookmarks });
  }

  private _addLogos = mergeMap((results: IBookmark[]) => {
    results.forEach((bookmark) => {
      if (bookmark.favorites && bookmark?.favorites?.length > 0)
        bookmark.favorites.map((r) => {
          if (r.vendor)
            r.vendor.logoUrl = this._vendorFormatService.getLogo(
              r?.vendor?.sustainmentId,
              r.vendor?.logo || ''
            );
        });
    });
    return of(results);
  });
}
