import { HttpUploadProgressEvent } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ClosableModal,
  IUploaderParameters,
  IFileUploadOutput,
  IMediaType,
} from 'sustainment-models';

const MAX_FILE_LENGTH = 200;

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent extends ClosableModal implements OnInit {
  @Input() public parameters: IUploaderParameters;
  @Input() public set uploadProgress(uploadProgress: HttpUploadProgressEvent) {
    if (uploadProgress?.total) {
      this.progress = Math.round(
        (100 * uploadProgress.loaded) / uploadProgress.total
      );
    }
  }

  @Output() public fileUpload = new EventEmitter<IFileUploadOutput>();

  public fileList: FileList | undefined;
  public uploading = false;
  public uploadErrorMessage: string;
  public selectionErrorMessage: string;
  public dragZoneClass = 'drag-preview';
  public progress = 0;
  public title = new UntypedFormControl('', [
    Validators.required,
    this.noWhitespaceValidator,
    Validators.maxLength(20),
  ]);
  public selectedMediaType: IMediaType;
  public readyToClose = false;

  public constructor(public activeModal: NgbActiveModal) {
    super();
  }

  public get fileNames(): string {
    if (this.selectionErrorMessage) {
      return '';
    }

    return !this.fileList?.length
      ? '* No file chosen'
      : Array.from(this.fileList)
          .map((f) => f.name)
          .join(', ');
  }

  public ngOnInit(): void {
    if (this.parameters.types?.length) {
      this.selectedMediaType = this.parameters.types[0];
    }
  }
  public onCancel(): void {
    this.activeModal.dismiss('Cancel click');
  }

  public onFileSelected(event: Event): void {
    let selectedFiles: FileList | null = null;
    const target = event.target as HTMLInputElement;
    if (target.files?.length) {
      selectedFiles = target.files;
      this.selectFiles(selectedFiles);
    }
  }

  public onUpload(): void {
    if (!this.fileList?.length || !this.titleIsValid()) return;
    if (this.fileList) {
      this.uploading = true;
      this.fileUpload.emit({
        files: this.fileList,
        title: this.title.value,
        mediaType: this.selectedMediaType,
      });
    }
  }

  public onUploadFinished(): void {
    this.uploading = false;
    this.readyToClose = true;
  }

  public onError(): void {
    this.uploading = false;
  }

  public onDragOver(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dragZoneClass = 'drag-over';
  }

  public onDragLeave(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dragZoneClass = 'drag-preview';
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragZoneClass = 'drag-preview';
    if (event.dataTransfer?.files) {
      const files: FileList = event.dataTransfer.files;
      this.selectFiles(files);
    }
  }

  public titleIsValid(): boolean {
    return !this.parameters?.caption || this.title.valid;
  }

  private selectFiles(selectedFiles: FileList): void {
    this.selectionErrorMessage = this.uploadErrorMessage = '';
    this.fileList = undefined;
    if (!selectedFiles) {
      return;
    }

    if (
      this.parameters?.maxAllowedFiles &&
      selectedFiles.length > this.parameters?.maxAllowedFiles
    ) {
      this.selectionErrorMessage = `* Select at most ${this.parameters?.maxAllowedFiles} files`;
      return;
    }

    const fileArray = Array.from(selectedFiles);
    if (
      this.parameters?.allowedFormats?.length &&
      fileArray.some(
        (f) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          !this.parameters.allowedFormats!.includes(
            f.name.split('.').pop()?.toLocaleLowerCase() ?? ''
          )
      )
    ) {
      this.selectionErrorMessage = `* Unsupported file format`;
      return;
    }

    if (
      this.parameters?.maxFileSize &&
      fileArray.reduce((a, b) => a + b.size, 0) > this.parameters?.maxFileSize
    ) {
      this.selectionErrorMessage = `* Max file size exceeded`;
      return;
    }

    if (fileArray.some((f) => f.name.length > MAX_FILE_LENGTH)) {
      this.selectionErrorMessage = `* File name is too long (max: 200 chars)`;
      return;
    }

    this.fileList = selectedFiles;
  }

  private noWhitespaceValidator(
    control: UntypedFormControl
  ): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
