/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export type EmailValidity = {
  suspect: boolean;
  suggestion?: string;
};

type EmailValidationResponse = {
  email: string;
  verdict: 'Valid' | 'Risky' | 'Invalid';
  score: number;
  local: string;
  host: string;
  suggestion: string;
  checks: {
    domain: {
      has_valid_address_syntax: boolean;
      has_mx_or_a_record: boolean;
      is_suspected_disposable_address: boolean;
    };
    local_part: {
      is_suspected_role_address: boolean;
    };
    additional: {
      has_known_bounces: boolean;
      has_suspected_bounces: boolean;
    };
  };
  source: string;
  ip_address: string;
};
@Injectable({
  providedIn: 'root',
})
export class EmailValidationService {
  public constructor(private httpClient: HttpClient) {}

  public validate(email: string): Observable<EmailValidity> {
    return this.httpClient
      .post<any[]>(environment.emailValidationApiUrl, {
        email,
      })
      .pipe(
        catchError(() => of([])),
        map((r) => {
          if (!r?.length || r[0].statusCode !== 200 || !r[0].body?.result) {
            return { suspect: true };
          }

          const result = r[0].body.result;
          if (result.suggestion) {
            return {
              suspect: true,
              suggestion: `${result.local}@${result.suggestion}`,
            };
          }

          if (this.isSuspectEmailResponse(result)) {
            return { suspect: true };
          }

          return { suspect: false };
        })
      );
  }

  private isSuspectEmailResponse(validation: EmailValidationResponse): boolean {
    return (
      !validation?.checks?.domain.has_valid_address_syntax ||
      !validation.checks.domain.has_mx_or_a_record ||
      validation.checks.domain.is_suspected_disposable_address ||
      validation.checks.additional.has_known_bounces ||
      validation.checks.additional.has_suspected_bounces
    );
  }
}
