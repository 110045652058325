import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  public constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated$.pipe(
      first(),
      map(
        (isAuthenticated) =>
          !isAuthenticated || this.router.createUrlTree(['/home'])
      )
    );
  }
}
