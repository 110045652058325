import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SearchConfiguration } from 'sustainment-models';
import { SearchConfigurationStore } from './search-configuration.store';

@Injectable({ providedIn: 'root' })
export class SearchConfigurationQuery extends Query<SearchConfiguration> {
  public constructor(protected store: SearchConfigurationStore) {
    super(store);
  }
}
