import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IBookmarkList } from 'sustainment-models';
import { BookmarkStore } from './bookmark.store';

@Injectable({ providedIn: 'root' })
export class BookmarkQuery extends Query<IBookmarkList> {
  public constructor(protected store: BookmarkStore) {
    super(store);
  }
}
