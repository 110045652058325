import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserAccountQuery } from 'src/app/store/userAccount/user-account.query';
import { environment } from 'src/environments/environment';
import {
  CapitalizeWordsDirective,
  IdentityApi,
  InteractionsApi,
  LoggerDatadogService,
  NotifyService,
  OktaService,
  detectAdblock,
  isBehindProxy
} from 'sustainment-component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    { provide: 'CapitalizeWordsFirstName', useClass: CapitalizeWordsDirective },
    { provide: 'CapitalizeWordsLastName', useClass: CapitalizeWordsDirective }
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  public errorMsg = '';
  public isLoggingIn = false;
  public focusEmail = false;
  public focusPassword = false;
  public loading = true;
  public isRegistering = false;

  private _returnUrl: string;
  private _registerButton: HTMLInputElement;
  private _subscriptions = new Subscription();

  public constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _ngZone: NgZone,
    private _identityApi: IdentityApi,
    private _activatedRoute: ActivatedRoute,
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private _oktaService: OktaService,
    private _logService: LoggerDatadogService,
    private _authService: AuthService,
    private _userAccountQuery: UserAccountQuery,
    private interactionsApi: InteractionsApi,
    private notifyService: NotifyService,
    @Inject('CapitalizeWordsFirstName')
    private _capitalizeWordsFirstName: CapitalizeWordsDirective,
    @Inject('CapitalizeWordsLastName')
    private _capitalizeWordsLastName: CapitalizeWordsDirective
  ) {
    _oktaService.widget.on('afterError', function (context: any, error: any) {
      _logService.info(JSON.stringify(error), context);
    });
  }

  public ngOnInit(): void {
    this._returnUrl = this._route.snapshot.queryParamMap.get('src') || '/';

    // Check if the 'alreadyRegistered' parameter is present in the URL
    const alreadyRegistered =
      this._route.snapshot.queryParamMap.has('alreadyRegistered');

    if (alreadyRegistered) {
      this.notifyService.showInfo(
        'Company already registered. Please login to continue.'
      );
    }

    const inviteCode = this._route.snapshot.queryParamMap.get('ref');

    if (inviteCode && this._route.snapshot.url[1]?.path === 'register') {
      this.loading = true;
      this._subscriptions.add(
        this.interactionsApi.getOdysseus(inviteCode).subscribe((res) => {
          if (!res.stillValid) {
            window.location.href = '/login?alreadyRegistered=true';
          }
        })
      );
    }

    //Redirect out of login when getting a valid appKey
    this._subscriptions.add(
      this._userAccountQuery.userSession$
        .pipe(first((session) => !!session?.appKey))
        .subscribe(() => {
          const refCode = this._route.snapshot.queryParamMap.get('state');
          if (refCode && refCode !== 'a') {
            this._subscriptions.add(
              this._identityApi.setUserCode(refCode).subscribe()
            );
          }

          this._router.navigate([this._returnUrl]);
        })
    );

    //If the user has a pending organization redirect to pending screen
    this._subscriptions.add(
      this._userAccountQuery.userAccount$
        .pipe(
          first(
            (account) =>
              account?.organizations.length &&
              (account.organizations[0] as any).pending
          )
        )
        .subscribe((account) =>
          this._router.navigateByUrl('/pending-activation', {
            state: { companyName: account.organizations[0].name }
          })
        )
    );

    this.initializeOkta();
  }

  private initializeOkta(): void {
    this._subscriptions.add(
      this._oktaService.validatingEmail$.subscribe((value) => {
        if (this._registerButton) this._registerButton.disabled = value;
      })
    );

    combineLatest([
      this._authService.isAuthenticated$,
      this._authService.loadingSession$
    ]).subscribe(([isAuthenticated, loadingSession]) => {
      if (!loadingSession && !isAuthenticated) {
        this.loading = false; // show the login form
      }
    });

    if (this._oktaService.widget.authClient.isLoginRedirect()) {
      this._returnUrl = this.getReturnUrl();
      this._oktaService.widget.authClient.token
        .parseFromUrl()
        .then((res) => {
          if (res.tokens) {
            this._authService.login(res.tokens);
          }
        })
        .catch(() => {
          this._router.navigate([this._returnUrl]);
        });
    } else {
      this._oktaService.widget.on('afterRender', (ev: any) => {
        if (ev.controller === 'primary-auth') {
          const registerLink = this._elRef.nativeElement.querySelector(
            '.registration-link'
          ) as HTMLAnchorElement;
          if (registerLink) {
            registerLink.addEventListener('click', () => {
              registerLink.innerText = 'Please wait...';
              registerLink.classList.add('disabled-href');
            });
          }
        }
        if (ev.controller === 'registration') {
          this.onRegisterClick();
        }
      });
      this.showLogin();
    }

    this._route.queryParams.subscribe((params) => {
      if (params['error']) {
        this.loading = false;
        this.errorMsg = params['error_description'] || 'Authorization error';
        this._authService.closeSession();
        this.showLogin();
      }
    });
  }

  private showLogin(): void {
    this._oktaService.widget
      .showSignIn({ el: '#okta-signin-container' })
      .then((response) => {
        if (response.status === 'SUCCESS' && response.tokens) {
          this._authService.login(response.tokens, true);

          const email = response.tokens?.idToken?.claims?.email;

          if (email) {
            this.checkAdblock(email);
            this.checkProxy(email);
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this._oktaService.widget.remove();
    this._subscriptions.unsubscribe();
  }

  private getReturnUrl(): string {
    const defaultUrl = this._route.snapshot.queryParamMap.get('src') || '/';

    const originalUri = this._oktaService.widget.authClient.getOriginalUri();

    if (originalUri && originalUri.indexOf('?src=') >= 0) {
      const search = decodeURIComponent(
        originalUri?.substring(originalUri.indexOf('?src='), originalUri.length)
      ).replace('?src=', '');

      return search;
    }

    return defaultUrl;
  }

  private onRegisterClick(): void {
    this.addSocialButtons();

    const registerContainer =
      this._elRef.nativeElement.querySelector('.o-form-button-bar');
    this._registerButton = this._elRef.nativeElement.querySelector(
      'input.button.button-primary'
    );
    this._registerButton.disabled = true;

    const firstNameInput = document.querySelector('input[name="firstName"]');
    const lastNameInput = document.querySelector('input[name="lastName"]');

    firstNameInput?.addEventListener(
      'input',
      this._capitalizeWordsFirstName.onInput
    );
    lastNameInput?.addEventListener(
      'input',
      this._capitalizeWordsLastName.onInput
    );

    const div = this._renderer.createElement('div') as HTMLDivElement;
    div.className = 'custom-checkbox';

    const input = this._renderer.createElement('input') as HTMLInputElement;
    input.className = 'checkedFocus';
    input.type = 'checkbox';
    input.id = 'input-tos';
    input.name = 'tos';

    const label = this._renderer.createElement('label') as HTMLLabelElement;
    input.setAttribute('role', 'button');

    input.addEventListener('click', () => {
      this._registerButton.toggleAttribute('disabled');
      if (label.classList.contains('checked')) {
        label.classList.remove('checked');
      } else {
        label.classList.add('checked');
      }
    });
    input.addEventListener('focus', () => label.classList.add('focus'));
    input.addEventListener('blur', () => label.classList.remove('focus'));

    label.addEventListener('mouseover', () => label.classList.add('hover'));
    label.addEventListener('mouseleave', () => label.classList.remove('hover'));

    label.htmlFor = 'input-tos';
    label.innerHTML = `I have read and agree to the <a href="https://sustainment.tech/terms-of-use/" target="_blank">Terms of Service</a> including the <a href="https://sustainment.tech/privacy-policy/"
      target="_blank">User Agreement and Privacy Policy</a>`;

    div.appendChild(input);
    div.appendChild(label);

    this._renderer.insertBefore(registerContainer, div, this._registerButton);
    this.isRegistering = true;
  }

  private addSocialButtons(): void {
    const state = this._activatedRoute.snapshot.queryParamMap.get('ref') || 'a';

    const googleButton = this._renderer.createElement(
      'button'
    ) as HTMLAnchorElement;
    googleButton.classList.add(
      'social-auth-button',
      'social-auth-google-button',
      'link-button',
      'default-custom-button'
    );
    googleButton.innerText = 'Sign up with Google';
    googleButton.href = '#';
    googleButton.addEventListener('click', () =>
      this._oktaService.widget.authClient.signInWithRedirect({
        originalUri: window.location.origin + '/login',
        state,
        nonce: 'b',
        idp: environment.okta.googleId
      })
    );

    const registrationDivider = this._renderer.createElement(
      'div'
    ) as HTMLDivElement;
    registrationDivider.classList.add('registration-divider');
    registrationDivider.innerHTML = `<span> OR </span>`;

    const primaryAuth = this._renderer.createElement('div') as HTMLDivElement;
    primaryAuth.classList.add('primary-auth-container');
    primaryAuth.appendChild(registrationDivider);
    primaryAuth.appendChild(googleButton);

    const registrationElement = this._elRef.nativeElement.querySelector(
      '.registration'
    ) as HTMLDivElement;
    this._renderer.appendChild(registrationElement, primaryAuth);
  }

  private checkAdblock(email: string): void {
    detectAdblock().then((hasAdblock) => {
      if (hasAdblock) {
        const message = `PortalApp: ${email} is using adblocker`;

        this._logService.info(message);
        this._identityApi.sendLog(message).subscribe();
      }
    });
  }

  private checkProxy(email: string): void {
    if (isBehindProxy()) {
      const message = `PortalApp: ${email} is behind proxy`;

      this._logService.info(message);
      this._identityApi.sendLog(message).subscribe();
    }
  }
}
