import { NgModule, ModuleWithProviders } from '@angular/core';

// Global Services Setup
import { StateAPI } from './api/state.api';
import { DocumentTypeAPI } from './api/documenttypes.api';
import { LogosAPI } from './api/logos.api';
import { CoverAPI } from './api/cover.api';
import { DialogService } from 'primeng/dynamicdialog';
import { VendorMediaApi } from './api/vendor-media.api';
import { EdcAPI } from './api/edc.api';
import { ProjectsAPI } from './api/projects.api';
import { StripeAPI } from './api/stripe-service.api';
import { PlatformCommuncationApi } from './api/platform-communication.api';
import { ConfirmationService } from 'primeng/api';
import { OnboardingAPI } from './api/onboarding.api';
import { PartAPI } from './api/part.api';

@NgModule({})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        StateAPI,
        DocumentTypeAPI,
        LogosAPI,
        CoverAPI,
        DialogService,
        VendorMediaApi,
        EdcAPI,
        ProjectsAPI,
        StripeAPI,
        PlatformCommuncationApi,
        ConfirmationService,
        OnboardingAPI,
        PartAPI,
      ],
    };
  }
}
