import { Injectable } from '@angular/core';
import { ClassificationApi } from 'sustainment-component';
import { IClassification } from 'sustainment-models';
import { ClassificationStore } from './classification.store';

@Injectable({ providedIn: 'root' })
export class ClassificationActions {
  public constructor(
    private classificationAPI: ClassificationApi,
    private store: ClassificationStore
  ) {}

  public getClassificationData(): void {
    if (!this.store.getValue()?.classifications?.length)
      this.classificationAPI
        .getClassificationData()
        .subscribe((classifications: IClassification[]) => {
          this.store.update(() => ({
            classifications,
          }));
        });
  }
}
