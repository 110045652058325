import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs/internal/Observable';
import { IGeoquery, IGeoqueryPost } from 'sustainment-models';
import { VendorSearchStore } from './vendor-search.store';


@Injectable({ providedIn: 'root' })
export class VendorSearchQuery extends Query<IGeoquery> {
  public constructor(protected store: VendorSearchStore) {
    super(store);
  }

  public filters$: Observable<IGeoqueryPost> = this.select((s) => s.request!);

  public selectSearching(): Observable<boolean> {
    return this.select((s) => s.searching);
  }
}
