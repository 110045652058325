import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IClassificationState } from 'sustainment-models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'classification' })
export class ClassificationStore extends Store<IClassificationState> {
  public constructor() {
    super({});
  }
}
