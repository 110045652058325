import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FileUploaderComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class FileUploaderModule {}
