import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationModule } from './modules/registration/registration.module';
import { VendorInfoModule } from './modules/vendor-info/vendor-info.module';
import { AuthGuard } from './guards/auth.guard';
import { LoginContainerComponent } from './containers/login/login.container';
import { GenericErrorComponent } from 'sustainment-component';
import { ClaimedWelcomeScreenComponent } from './components/features/registration/claimed-welcome-screen/claimed-welcome-screen.component';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: (): Promise<typeof VendorInfoModule> =>
      import('./modules/vendor-info/vendor-info.module').then(
        (m) => m.VendorInfoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'registration',
    loadChildren: (): Promise<typeof RegistrationModule> =>
      import('./modules/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pending-activation',
    component: ClaimedWelcomeScreenComponent,
  },
  {
    path: 'login',
    component: LoginContainerComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'login/callback',
    component: LoginContainerComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'signin/register',
    component: LoginContainerComponent,
    canActivate: [LoginGuard],
  },
  { path: 'error', component: GenericErrorComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
