import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IRoleState } from './role.model';
import { RoleStore } from './role.store';

@Injectable({ providedIn: 'root' })
export class RoleQuery extends Query<IRoleState> {
  public constructor(protected store: RoleStore) {
    super(store);
  }
}
