import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, tap, debounceTime, switchMap } from 'rxjs/operators';
import {
  NoteAPI,
  ClosableModalService,
  VendorFormatService,
} from 'sustainment-component';
import { BookmarkActions } from '../bookmark/bookmark.action';
import {
  INote,
  INoteCreation,
  INoteFilterer,
  NotesSortOrder,
} from 'sustainment-models';
import { NoteStore } from './note.store';

@Injectable({ providedIn: 'root' })
export class NoteActions {
  public noteFilterer = new Subject<INoteFilterer>();

  public constructor(
    private _store: NoteStore,
    private _api: NoteAPI,
    private _bookmarkActions: BookmarkActions,
    private _closeableModals: ClosableModalService,
    private _vendorFormatService: VendorFormatService
  ) {
    this.setNoteFilterObservable();
  }

  public getNotes(): void {
    if (!this._store.getValue().notes) {
      this._store.setLoading(true);
      this._store.update({ notes: [] });

      this.getAllNotesApi(NotesSortOrder.ASC).subscribe((results: INote[]) => {
        this._store.update({
          notes: results || [],
        });
        this._store.setLoading(false);
      });
    }
  }

  public getNotesSorted(
    sortOrder: NotesSortOrder,
    removeDuplicates = true
  ): void {
    this._store.setLoading(true);
    this.getAllNotesApi(sortOrder, removeDuplicates).subscribe(
      (results: INote[]) => {
        this._store.update({
          notes: results || [],
        });
        this._store.setLoading(false);
      }
    );
  }

  public setNotes(results: INote[]): void {
    this._store.update({
      notes: results || [],
    });
  }

  public addNote(noteCreation: INoteCreation): void {
    this._store.setLoading(true);
    this._api.createNote(noteCreation).subscribe((results) => {
      const note = results;
      note.logo = this._vendorFormatService.getLogo(
        note.sustainmentId,
        note.logo
      );

      const oldValue = this._store.getValue();
      const notes = oldValue.notes
        ? oldValue.notes.slice(0, oldValue.notes.length)
        : [];

      note.name = noteCreation.vendorName;
      //Adding current date/time in UTC to fix sorting bug
      const now = new Date();
      note.dateUTC = new Date(
        now.getTime() + now.getTimezoneOffset() * 60 * 1000
      ).toISOString();

      notes.push(note);
      this._store.update({ notes });
      this._store.setLoading(false);
      const vendorNoteLength = notes.filter(
        (n) => n.companySustainmentId === note.companySustainmentId
      ).length;
      this._bookmarkActions.updateNoteValue(
        note.companySustainmentId,
        vendorNoteLength
      );
      this._closeableModals.close();
    });

    this._store.setLoading(false);
  }

  public updateNote(note: INoteCreation): void {
    this._api
      .updateNote(note)
      .pipe(
        tap((newData) => {
          const oldValue = this._store.getValue();
          const notes = oldValue.notes.map((n) => {
            if (n.noteId === newData.noteId) {
              return {
                ...n,
                note: newData.note,
                visibility: newData.visibility,
              };
            }
            return n;
          });
          this._store.update({ notes });
          this._store.setLoading(false);
          this._closeableModals.close();
        })
      )
      .subscribe(() => this._closeableModals.reset());
  }

  public updateNoteValueInLists(
    vendorOdysseusId: string,
    notesCount: number
  ): void {
    this._bookmarkActions.updateNoteValue(vendorOdysseusId, notesCount);
  }

  public deleteNote(note: INote): void {
    this._api.deleteNote(note).subscribe(() => {
      this._store.update((currentValue) => ({
        notes: currentValue.notes.filter((b) => b.noteId !== note.noteId),
      }));
      const oldValue = this._store.getValue();
      const notes = oldValue.notes.filter(
        (b) => b.companySustainmentId === note.companySustainmentId
      );
      this._bookmarkActions.updateNoteValue(
        note.companySustainmentId,
        notes.length
      );
      this._closeableModals.reset();
    });
  }

  private getAllNotesApi(
    sortOrder: NotesSortOrder,
    removeDuplicates = true
  ): Observable<INote[]> {
    return this._api.getNotesSorted(sortOrder).pipe(
      mergeMap((results) => {
        if (results.length) {
          let notes = results;

          if (removeDuplicates) notes = this.getNonDuplicatedNotes(results);

          notes.map(
            (note) =>
              (note.logo = this._vendorFormatService.getLogo(
                note.sustainmentId,
                note.logo
              ))
          );
        }
        return of(results);
      })
    );
  }

  private setNoteFilterObservable(removeDuplicates = true): void {
    this.noteFilterer
      .pipe(
        debounceTime(500),
        switchMap((res) =>
          this._api.getNotesFiltered(res.search, res.isMine, res.org).pipe(
            tap(() => this._store.setLoading(true)),
            mergeMap((results) => {
              if (results.length) {
                let notes = results;

                if (removeDuplicates)
                  notes = this.getNonDuplicatedNotes(results);

                notes.map(
                  (note) =>
                    (note.logo = this._vendorFormatService.getLogo(
                      note.sustainmentId,
                      note.logo
                    ))
                );
              }
              return of(results);
            })
          )
        )
      )
      .subscribe({
        next: (res) => {
          this._store.update({
            notes: res,
          });
          this._store.setLoading(false);
        },
      });
  }

  private getNonDuplicatedNotes(results: INote[]): INote[] {
    return results.filter(
      (note, index) =>
        index ==
        results.findIndex(
          (nextNote) => note.sustainmentId == nextNote.sustainmentId
        )
    );
  }
}
