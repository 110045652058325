import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  NotifyService,
  RecentlyViewedAPI,
  VendorFormatService,
} from 'sustainment-component';
import { RecentlyViewedStore } from './recently-viewed.store';

@Injectable({ providedIn: 'root' })
export class RecentlyViewedAction {
  public constructor(
    private _store: RecentlyViewedStore,
    private _api: RecentlyViewedAPI,
    private _notifyService: NotifyService,
    private _vendorFormatService: VendorFormatService
  ) {}

  public getRecentlyViewedCompanies(): void {
    if (!this._store.getValue().companies) {
      this.getLatestData();
    }
  }

  public addRecentlyViewedCompany(odysseusId: string, feature: string): void {
    this._api.addRecentlyViewedCompany(odysseusId, feature).subscribe(
      () => {
        this.getLatestData();
      },

      /* eslint no-console: ["error", { allow: ["error"] }] */
      (error) => console.error(error)
    );
  }

  private getLatestData(): void {
    this._store.setLoading(true);
    this._store.update({ companies: [] });
    this._api
      .getRecentlyViewedCompanies()
      .pipe(
        mergeMap((companies) => {
          if (companies.length) {
            companies.map(
              (company) =>
                (company.logoUrl = this._vendorFormatService.getLogo(
                  company.sustainmentId,
                  company.logo || ''
                ))
            );
          }
          return of(companies);
        })
      )
      .subscribe(
        (results) => {
          this._store.update({
            companies: results.splice(0, 5) || [],
          });
          this._store.setLoading(false);
        },
        () => {
          this._store.setLoading(false);
        }
      );
  }
}
