import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi } from 'sustainment-component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VendorMediaApi extends BaseApi {
  public constructor(public http: HttpClient) {
    super(http);
  }
  protected urlBase = environment.mediaApiBaseUrl;

  public getDocument(
    documentId: string,
    sustainmentId: string | undefined
  ): Observable<Blob> {
    const url = `media/vendor/${sustainmentId}/document/${documentId}`;
    return this.getBlob(url);
  }
}
