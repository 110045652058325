import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IdentityApi } from 'sustainment-component';
import { RoleStore } from './role.store';

@Injectable({ providedIn: 'root' })
export class RoleAction {
  public constructor(private _api: IdentityApi, private _store: RoleStore) {}

  public getRoles(): void {
    if (!this._store.getValue().userRoles) {
      this._store.setLoading(true);

      this._api
        .getRoles()
        .pipe(
          map((results) => {
            const roles = results.map((role) => {
              return { id: role.roleId, title: role.description };
            });
            return roles;
          })
        )
        .subscribe((userRoles) => {
          this._store.update({ userRoles });
          this._store.setLoading(false);
        });
    }
  }
}
