import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IUserManagementStore } from './user-management.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'userManagement' })
export class UserManagementStore extends Store<IUserManagementStore> {
  public constructor() {
    super({});
  }
}
